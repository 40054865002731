import instance from '../axios';

class LimitesDeConsultasCorretor {
    route;
  constructor() {
    this.route = 'api/Core'
  }

  async getCorretor(text) {
    
    return await instance({
      method: "Get",
      url: `/api/Broker/BuscarCorretoresPorSeguradora?texto=${text}`,            
    }).then(data=> {   
        return data.data.Response
    }) 
  }

  async cadastrarLimiteConsultaCorretor(body){
    return await instance({
      method: "Post",
      url: "/api/Broker/CadastrarLimiteConsultaCorretor",
      data:body            
    })
  }

  async AtualizarLimiteConsultaCorretor(body){     
      return await instance({
        method: "Post",
        url: "/api/Broker/AtualizarLimiteConsultaCorretor",
        data:body            
      })
  }

  async listarCorretoresComLimitacao(){
    return await instance({
      method: "Get",
      url: `/api/Broker/ListarParametrosLimiteConsulta`,            
    }).then(data=> {   
        return data.data.Response
    })      
  }

  async desativarLimiteConsultaCorretor(body){
    return await instance({
      method: "Post",
      url: "/api/Broker/AtualizarLimiteConsultaCorretor",
      data:body            
    })
  }
}

export default new LimitesDeConsultasCorretor();