<template>
  <div>
    <div class="branch-container parametrizacao">
      <div class="history" v-if="lastLog">
        <span class="pl-3 bold" style="margin-right: 5px; font-size: 12px;">
          Última alteração em {{ $utils.dateToDDMMYYYY(lastLog.Data) }} por
          {{ lastLog.NomeUsuario }}
        </span>
        <el-button round @click="popupHistoricoAlteracoes = true"
          ><i class="el-icon-timer"></i> Histórico de alterações</el-button
        >
      </div>
      <vs-row>
        <vs-col class="title">
          <span class="pl-3 bold">
            Dados de acesso às consultas
          </span>
        </vs-col>
      </vs-row>
      <div class="main-card">
        <div class="card-gray">
          <div class="left">
            <span class="pl-3 bold">
              Ativar regras
            </span>
          </div>
          <div class="right">
            <vs-switch class="mr-3" v-model="dataParams[0].Value" />
            <label for="" style="font-size: 13px; margin-right: 12px;">
              {{ dataParams[0].Value ? "Ativado" : "Desativado" }}</label
            >
          </div>
        </div>

        <div class="card-data" v-if="dataParams[0].Value">
          <vs-row>
            <vs-col vs-w="12" vs-sm="12" class="pb-5">                
              <p style="color:rgb(142, 142, 142);font-weight: bold">Limite geral</p>
            </vs-col>                
            <vs-col vs-w="4" vs-sm="12">              
              <p class="label-input">
                Limite máximo consultas por mês <b style="color:red">*</b>
              </p>
              <vs-input class="w-full" v-model="dataParams[1].Value" />
            </vs-col>

            <vs-col vs-w="4" vs-sm="12">
              <p class="label-input">
                Limites de alertas preventivos <b style="color:red">*</b>
              </p>
              <vs-input class="w-full" v-model="dataParams[2].Value" />
            </vs-col>
          </vs-row>

          <hr style="margin-top: 25px;opacity: 0.3;" />

          <vs-row class="pb-10">           
            <vs-col vs-w="12" vs-sm="12" class="pb-5">                
              <p style="color:rgb(142, 142, 142);font-weight: bold">Limite por corretor</p>
            </vs-col>   
            
              <multiselect
                v-model="buscarCorretorValue"
                class="w-full"
                id="buscar-corretor"
                label="Name"
                open-direction="bottom"
                placeholder="Digite o nome ou CNPJ do corretor"
                :options="dadosBuscaCorretor"
                :searchable="true"
                :loading="isLoading"
                :internal-search="false"
                :clear-on-select="false"
                :options-limit="5"
                :limit="5"
                :max-height="600"
                :show-no-options="false"
                :show-no-results="true"
                :hide-selected="true"
                @search-change="fetchCorretor"
                select-label=""
              >
                <template slot="option" slot-scope="props">
                  <div 
                    class="option__desc"       
                    @click="openLimitCorretorDialog(props.option)"             
                  >
                    <span class="option__title">
                      <b>{{ props.option.Name}}</b>
                    </span>
                    <div style="display:flex"  class="pt-2" v-if="!!props.option.CNPJ">
                      <b>CNPJ:</b>
                      <p>{{ props.option.CNPJ}}</p>  
                    </div>                                                                    
                  </div>
                </template>               
                <span slot="noResult">Não foi encontrado nenhum corretor na base</span>
              </multiselect>
             
          </vs-row>
          <hr style="margin-top: 25px;opacity: 0.3;" />
          <vs-row>
             <vs-col vs-w="12" vs-sm="12" class="pb-5">                
              <p style="color:rgb(142, 142, 142);font-weight: bold">Corretores com limites personalizados</p>
            </vs-col>   
            <vs-col>
              <vs-table
                class="corretorLimitsTable"
                :data="corretoresComLimitacao"
                :hoverFlat="true"
                noDataText="Nenhuma corretor encontrado"                
              >
                <template slot="thead">
                  <vs-th>CNPJ</vs-th>
                  <vs-th>Nome</vs-th>
                  <vs-th>Limite de consultas</vs-th>
                  <vs-th>Limite de avisos</vs-th>
                   <vs-th>Status</vs-th>
                  <vs-th>Configurações</vs-th>
                </template>  
                <template>              
                  <vs-tr :key="i" v-for="(item, i) in corretoresComLimitacao">
                    <vs-td>
                      {{ item.CNPJ || 'CNPJ não cadastrado' }}
                    </vs-td>
                    <vs-td>
                      {{ item.NomeCorretor }}
                    </vs-td>
                    <vs-td>
                      {{ item.LimiteMaximo }}
                    </vs-td>
                    <vs-td>
                      {{ item.LimiteAviso }}
                    </vs-td>
                    <vs-td>
                      {{ item.Ativo ? 'Ativo' : 'Inativo' }}
                    </vs-td>
                     <vs-td>
                      <vs-dropdown vs-trigger-click class="cursor-pointer button-drop-down" color='blue'>
                        <small class="cursor-pointer">
                          <span >Editar</span>
                          <i class="onpoint-caret-down iconAdjust"/>
                        </small>
                        <vs-dropdown-menu class="w-32">
                            <vs-dropdown-item @click="openLimitCorretorUpdateDialog(item)">Editar Corretor</vs-dropdown-item>
                            <vs-dropdown-item @click="desativarLimiteConsultaCorretor(item)">Desativar Corretor</vs-dropdown-item>
                        </vs-dropdown-menu>
                      </vs-dropdown>
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </vs-col>  
          </vs-row>
          <hr style="margin-top: 25px;opacity: 0.3;" />
          <vs-row>
            <vs-col vs-w="4" vs-sm="12">
              <p class="label-input">
                Título para mensagem de alerta <b style="color:red">*</b>
              </p>
              <vs-input
                class="w-full"
                v-model="emailTemplate[0].Title"
                @blur="updateTitle(0)"
              />
            </vs-col>
            <vs-col vs-w="4" vs-sm="12">
              <p class="label-input">
                Email para receber alerta: <b style="color:red">*</b>
              </p>
              <vs-input class="w-full" v-model="dataParams[3].Value" />
            </vs-col>
            <vs-col vs-w="12" vs-sm="12">
              <br />
              <p class="label-input">
                Mensagem de alerta: <b style="color:red">*</b>
              </p>
              <el-button type="primary" class="addtag" @click="addTagAction(0)">
                + Inserir Tag</el-button
              >
              <jodit-editor
                id="term-quill"
                ref="term-quill"
                v-model="emailTemplate[0].Body"
                :config="joditConfig"
                read-only="true"
              />
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-w="4" vs-sm="12">
              <p class="label-input">
                Título para mensagem de cancelamento <b style="color:red">*</b>
              </p>
              <vs-input
                class="w-full"
                v-model="emailTemplate[1].Title"
                @blur="updateTitle(0)"
              />
            </vs-col>
            <vs-col vs-w="12" vs-sm="12">
              <br />
              <p class="label-input">
                Mensagem de cancelamento: <b style="color:red">*</b>
              </p>
              <el-button type="primary" class="addtag" @click="addTagAction(1)">
                + Inserir Tag</el-button
              >
              <jodit-editor
                id="term-quill"
                ref="term-quill"
                v-model="emailTemplate[1].Body"
                :config="joditConfig"
                read-only="true"
              />
            </vs-col>
          </vs-row>
        </div>
        <vs-row vs-type="flex" vs-justify="end" class="mt-5">
          <vs-col
            vs-type="flex"
            vs-justify="end"
            vs-align="center"
            vs-lg="3"
            vs-sm="12"
          >
            <vs-button class="button-primary text-center" @click="update()">
              Salvar Alterações
            </vs-button>
          </vs-col>
        </vs-row>
      </div>
    </div>
    <!-- Pop up Histórico de alterações -->
    <el-dialog
      :visible.sync="popupHistoricoAlteracoes"
      close-on-click-modal
      modal-append-to-body
      center
      class="history-change"
    >
      <div class="pop-up-content">
        <div class="pop-up-content-header">
          <img
            src="@/assets/images/icons/ccg-icons/history-icon-ccg.svg"
            alt="Ícone de excluir representante"
            width="24"
            height="24"
          />
          <h3>Histórico de alterações</h3>
        </div>

        <div class="pop-up-content-comparation">
          <div class="pop-up-content-comparation-header">
            <div class="pop-up-content-comparation-header-data">
              Dados
            </div>
            <div class="pop-up-content-comparation-header-divider"></div>
            <div class="pop-up-content-comparation-header-before">
              Antes
            </div>
            <div class="pop-up-content-comparation-header-divider"></div>
            <div class="pop-up-content-comparation-header-after">
              Depois
            </div>
          </div>
          <ul class="pop-up-content-comparation-items">
            <li
              class="pop-up-content-comparation-item"
              v-for="(alteracao, index) in logs"
              :key="index"
            >
              <div class="pop-up-content-comparation-item-data">
                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    Data:
                  </p>
                  <p
                    class="pop-up-content-comparation-item-info"
                    v-if="alteracao.Data"
                  >
                    {{ $utils.dateToDDMMYYYY(alteracao.Data) }}
                  </p>
                </div>

                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    Usuário:
                  </p>
                  <p class="pop-up-content-comparation-item-info">
                    {{ alteracao.NomeUsuario }}
                  </p>
                </div>

                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    Ação:
                  </p>
                  <p class="pop-up-content-comparation-item-info">
                    {{ alteracao.Acao }}
                  </p>
                </div>
              </div>

              <div class="pop-up-content-comparation-item-divider"></div>

              <div class="pop-up-content-comparation-item-before">
                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-info">
                    <el-button
                      v-if="alteracao.Antes.length > 0"
                      type="primary"
                      icon="el-icon-view"
                      circle
                      @click="showItens(alteracao.Antes)"
                      >Visualizar</el-button
                    >
                  </p>
                </div>
              </div>

              <div class="pop-up-content-comparation-item-divider-arrow">
                <i class="el-icon-right"></i>
              </div>

              <div class="pop-up-content-comparation-item-after">
                <div class="pop-up-content-comparation-item-row">
                  <p class="pop-up-content-comparation-item-title">
                    <el-button
                      v-if="alteracao.Depois.length > 0"
                      type="primary"
                      icon="el-icon-view"
                      circle
                      @click="showItens(alteracao.Depois)"
                      >Visualizar</el-button
                    >
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div slot="footer" class="dialog-footer pop-up-footer">
        <el-button type="primary" @click="popupHistoricoAlteracoes = false"
          >Fechar</el-button
        >
      </div>
    </el-dialog>
    <vs-popup title="Inserir Tag na mensagem" :active.sync="modalTags">
      <div class="flex flex-col items-center pt-4">
        <el-select
          v-model="tagSelected"
          filterable
          placeholder="Escolha a tag desejada"
        >
          <el-option
            v-for="item in tags"
            :key="item.Name"
            :label="item.Label"
            :value="item.Name"
          >
          </el-option>
        </el-select>

        <div class="vx-row flex justify-center mt-5">
          <div>
            <vs-button
              color="red"
              class="mr-1"
              @click.native="
                modalTags = false;
                tagSelected = '';
              "
            >
              <span class="font-semibold">
                Cancelar
              </span>
            </vs-button>
            <vs-button
              @click.native="addTagObject()"
              :disabled="tagSelected.lenght <= 0"
            >
              <span class="font-semibold">
                Confirmar
              </span>
            </vs-button>
          </div>
        </div>
      </div>
    </vs-popup>

    <el-dialog
      :visible.sync="popupLimiteCorretor"
      close-on-click-modal
      modal-append-to-body
      center    
      v-if="detail"  
      title="Adicionar limites ao corretor"      
    >

    <div>
      <div>        
        <vs-row>          
          <vs-col vs-w="12" >
            <p>
              Nome:
            </p>
            <p>{{detail.Name}}</p>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-w="12" >
            <p>Cnpj:</p>
            <p>
              {{detail.CNPJ}}
            </p>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-w="7"  >
            <p>
              Limite máximo de consultas por mês
            </p>
            <vs-input v-model="detail.maxLimit"></vs-input>                     
          </vs-col>
          <vs-col vs-w="5">
            <p>
              Limite de avisos por mês
            </p>
            <vs-input v-model="detail.avisoLimit"></vs-input>           
          </vs-col>
        </vs-row>
      </div>
      <div class="actionsPopupLimiteCorretor mt-4">
        <vs-button class="cancelButtonLimiteCorretor" color="white" @click="popupLimiteCorretor=false">Cancelar</vs-button>
        <vs-button class="saveButtonLimiteCorretor" @click="cadastrarLimiteConsultaCorretor()">Salvar</vs-button> 
      </div>
    </div>   
    </el-dialog>

    <el-dialog
      :visible.sync="popupLimiteCorretorAtualizar"
      close-on-click-modal
      modal-append-to-body
      center    
      v-if="detail"  
      :title="'Atualizar limites do corretor'"      
    >

    <div>
      <div>        
        <vs-row>          
          <vs-col vs-w="12" >
            <p>
              Nome:
            </p>
            <p>{{detail.NomeCorretor }}</p>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-w="12" >
            <p>Cnpj:</p>
            <p>
              {{detail.CNPJ}}
            </p>
          </vs-col>
        </vs-row>
        <vs-row>
          <vs-col vs-w="7"  >
            <p>
              Limite máximo de consultas por mês
            </p>            
            <vs-input v-model="detail.LimiteMaximo"></vs-input>           
          </vs-col>
          <vs-col vs-w="5">
            <p>
              Limite de avisos por mês
            </p>
            <vs-input v-model="detail.LimiteAviso"></vs-input>
          </vs-col>
        </vs-row>
      </div>
      <div class="actionsPopupLimiteCorretor mt-4">
        <vs-button class="cancelButtonLimiteCorretor" color="white" @click="popupLimiteCorretorAtualizar=false">Cancelar</vs-button>
        <vs-button class="saveButtonLimiteCorretor" @click="AtualizarLimiteConsultaCorretor()">Salvar</vs-button> 
      </div>
    </div>   
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import instance from "@/axios";
import Multiselect from "vue-multiselect";
import LimitesDeConsultasCorretor from '../../providers/limites-de-consultas-corretor-provider.js'
export default {
  components: {
    Multiselect
  },
  data() {
    return {
      popupHistoricoAlteracoes: false,
      indexItem: 0,
      tagSelected: "",
      modalTags: false,
      tags: [],
      logs: {},
      lastLog: false,
      dataParams: false,
      emailTemplate: [
        { Title: "", Body: "" },
        { Title: "", Body: "" }
      ],
      joditConfig: { language: "pt_br" },
      teste: false,
      bolean: true,
      ramos: [],
      modalidadeList: [],
      groupList: [],
      modalityGroupList: [],
      buscarCorretorValue:"",
      isLoading:false,
      dadosBuscaCorretor:[],
      detail :null,
      popupLimiteCorretor:false,
      corretoresComLimitacao:null,
      popupLimiteCorretorAtualizar:false,
      detailObserver:null     
    };
  },
  async mounted() {
    await this.getData();
    await this.ObterLogAuditoria();
    await this.ObterTagsAutomaticas();
  },
  methods: {
    ...mapActions("seguradora-module", [
      "getAllBranch",
      "getModalitiesByBranch"
    ]),
    updateTitle(index) {
      this.$forceUpdate();
    },
    showItens(text) {
      this.$alert(text, " ", {
        confirmButtonText: "OK"
      });
    },
    addTagObject() {
      this.emailTemplate[this.indexItem].Body += `${this.tagSelected}`;
      this.modalTags = false;
      this.tagSelected = "";
    },
    addTagAction(index) {
      this.modalTags = true;
      this.indexItem = index;
    },
    async ObterTagsAutomaticas() {
      return instance({
        method: "get",
        url: "/api/Core/ObterTagsAutomaticas?tipoTag=2"
      }).then(response => {
        this.tags = response.data.Response;
      });
    },
    async ObterLogAuditoria() {
      return instance({
        method: "get",
        url: "/api/Parametros/ObterLogAuditoriaConsultaPJ"
      }).then(response => {
        this.logs = response.data.Response;
        this.lastLog = response.data.Response.slice(-1).pop();
      });
    },
    async update() {
      this.$vs.loading();
      return instance({
        method: "post",
        url: "/api/Parametros/AtualizarParametros",
        data: this.dataParams
      })
        .then(response => {
          console.log("ok");
          this.emailTemplate[0].EEmailTemplate = this.emailTemplate[0].EmailTemplate;
          this.emailTemplate[1].EEmailTemplate = this.emailTemplate[1].EmailTemplate;
          return instance({
            method: "post",
            url: "/api/Parametros/AtualizarEmailTemplate",
            data: this.emailTemplate
          })
            .then(response => {
              this.$vs.loading.close();
              this.$notify({
                title: "Success",
                message: "Dados atualizados com sucesso!",
                type: "success"
              });
              this.getData();
              this.ObterLogAuditoria();
            })
            .catch(error => {
              this.$vs.loading.close();
              this.$onpoint.errorModal(error.response.data.Errors);
            });
        })
        .catch(error => {
          this.$vs.loading.close();
          this.$onpoint.errorModal(error.response.data.Errors);
        });
    },
    async getData() {
      this.$vs.loading();
      return instance({
        method: "get",
        url: "/api/Parametros/ObterParametrosPorTipo?tipoParametro=1"
      })
        .then(response => {
          this.dataParams = response.data.Response;
          this.dataParams[0].Value =
            this.dataParams[0].Value === "true"
              ? (this.dataParams[0].Value = true)
              : false;
          return instance({
            method: "get",
            url: "/api/Parametros/ObterEmailTemplate?emailTemplate=34"
          })
            .then(response => {
              this.emailTemplate[0] = response.data.Response;
              return instance({
                method: "get",
                url: "/api/Parametros/ObterEmailTemplate?emailTemplate=35"
              })
                .then(response => {
                  this.$vs.loading.close();
                  this.emailTemplate[1] = response.data.Response;
                })
                .catch(error => {
                  this.$vs.loading.close();
                });
            })
            .catch(error => {
              this.$vs.loading.close();
            });
        })
        .catch(error => {
          this.$vs.loading.close();
        });
    },
    async fetchCorretor(event){   
      this.isLoading = true     
      if(event && event.length <=2) return this.isLoading = false
      
      this.dadosBuscaCorretor = await LimitesDeConsultasCorretor.getCorretor(event)     

      return this.isLoading = false
    },

    openLimitCorretorDialog(corretor){         
      this.detail = corretor
      !!this.detail.Id ||  !!this.detail.BrokerId ? this.popupLimiteCorretor= true : this.popupLimiteCorretor= false      
    },
    
    openLimitCorretorUpdateDialog(corretor){         
      this.detail = JSON.parse(JSON.stringify(corretor))
      this.detailObserver = corretor
      !!this.detail.BrokerId ? this.popupLimiteCorretorAtualizar= true : this.popupLimiteCorretorAtualizar= false      
    },

    async cadastrarLimiteConsultaCorretor(){

      const body =  {
        BrokerId: this.detail.Id,
        LimiteMaximo: this.detail.maxLimit,
        LimiteAviso: this.detail.avisoLimit,
        TemLimite: true
      }
      
      return await LimitesDeConsultasCorretor.cadastrarLimiteConsultaCorretor(body)
        .then(()=>{
        this.popupLimiteCorretor=false        
        this.$onpoint.successModal("Criado com sucesso")
      })
    },


    async AtualizarLimiteConsultaCorretor(){      
      const body =  {
        BrokerId: this.detail.BrokerId,
        LimiteMaximo: this.detail.LimiteMaximo,
        LimiteAviso: this.detail.LimiteAviso,
        Ativo: true
      }

      return await this.$onpoint.loadingModal("Estamos atualizando os limites do corretor", async () => {      
        return await LimitesDeConsultasCorretor.cadastrarLimiteConsultaCorretor(body)
          .then(()=>{
            this.popupLimiteCorretorAtualizar=false   
            this.detailObserver.LimiteMaximo = this.detail.LimiteMaximo
            this.detailObserver.LimiteAviso= this.detail.LimiteAviso
            this.detailObserver.Ativo = true
            this.$onpoint.successModal("Dados atualizados com sucesso")
          })
      })
    },

    async listarCorretoresComLimitacao(){
      return await LimitesDeConsultasCorretor.listarCorretoresComLimitacao()
        .then(data=> {   
            this.corretoresComLimitacao = data
        })      
    },

    async desativarLimiteConsultaCorretor(corretor){ 
      this.detail = corretor
      const body =  {
        BrokerId: this.detail.BrokerId,
        Ativo: false
      }
      await this.$onpoint.loadingModal("Estamos desativando os limites do corretor", async () => {
        return await LimitesDeConsultasCorretor.desativarLimiteConsultaCorretor(body)        
          .then(()=>{        
            this.$onpoint.successModal("Desativado com sucesso")
            this.detail.LimiteAviso=0
            this.detail.LimiteMaximo = 0
          })
      })
    },
  },
  created() {
    this.$appInsights.trackPageView({
      name: "param-modalidade",
      url: window.location.href
    }),
    this.listarCorretoresComLimitacao()
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/vuexy/components/vxtable.scss";

.branch-container {
  position: relative;
  background: #fff;
  box-shadow: 0px 3px 10px 8px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  margin-bottom: 24px;
  margin-top: 25px;

  .title {
    padding: 32px 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */

    /* grayscale/gray-4 */

    color: #8e8e8e;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .modality-group-content:not(:last-of-type) {
    border-bottom: 1px solid #e4e1e1;
  }

  .modality-group-content:not(:first-of-type) {
    padding-top: 16px;
  }

  .modality-group-content {
    margin: 0 16px 16px;
    padding-bottom: 16px;

    .modality-group-title {
      justify-content: space-between;
      padding-bottom: 28px;
      padding-top: 0px;
    }

    .data-content {
      color: #50555a;
      font-size: 14px;
      font-weight: 700;
    }

    .data-title {
      color: #8e8e8e;
      font-size: 12px;
      font-weight: 700;
    }
  }
}

.card-gray {
  background: #f7f7f7;
  color: #636363;
  font-weight: bold;
  border-radius: 10px;
  border: 1px solid #63636321;
  padding: 25px 15px;
  display: flex;

  .left,
  .right {
    width: 50%;
  }

  .left {
    text-align: left;
  }

  .right {
    display: flex;
    flex-direction: row-reverse;
  }
}

.main-card {
  padding: 15px 25px;
}

.history {
  position: absolute;
  top: -65px;
  right: 0;
}
</style>

<style lang="scss">
.vs-popup {
  width: auto !important;
}
.addtag {
  float: right;
  border-radius: 0px !important;
}
</style>

<style lang="scss" scoped>
.btn-view-changes {
  height: fit-content;
  padding: 10px 24px 10px 16px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;

  &:hover {
    background: rgba(var(--vs-primary), 0.8) !important;
  }
}

.el-button.is-disabled {
  background: rgba(var(--vs-primary), 0.8) !important;
  pointer-events: none;
}

.pop-up-content {
  &-header {
    width: 100%;
    text-align: center;
    color: rgba(var(--vs-primary), 1);
    padding-bottom: 16px;
    border-bottom: 1px solid #747474;

    & i {
      width: 20px;
      height: 20px;

      &::before {
        font-size: 20px;
      }
    }

    & h3 {
      color: #2c2c2c;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      margin-top: 8px;
    }
  }

  &-comparation {
    padding: 36px 0 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-header {
      width: 100%;
      display: flex;
      padding: 0 16px 0 0;

      &-before,
      &-after,
      &-divider,
      &-data {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      &-divider {
        margin: 0 18px;
      }

      &-before,
      &-after,
      &-data {
        width: 100%;
        height: 50px;
        border-radius: 8px 8px 0 0;
        font-size: 18px;
        font-weight: 700;
        color: rgba(13, 27, 51, 1);
      }

      &-data {
        background-color: rgba(236, 236, 236, 1);
      }

      &-before {
        background-color: rgba(236, 236, 236, 1);
      }

      &-after {
        background-color: rgba(174, 255, 192, 1);
      }
    }

    &-items {
      height: 450px;
      overflow: auto;
    }

    &-item {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      padding: 36px 0;
      border-bottom: 1px solid rgba(116, 116, 116, 1);

      &-before,
      &-after,
      &-divider,
      &-divider-arrow,
      &-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &-before,
      &-after,
      &-data {
        width: 100%;
      }

      &-divider {
        margin: 0 18px;
      }

      &-divider-arrow {
        & i {
          width: 36px;
          height: 36px;

          &::before {
            font-size: 36px;
            color: rgba(236, 236, 236, 1) !important;
          }
        }
      }

      &-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      &-title {
        width: 50%;
        text-align: end;
        font-size: 12px;
        line-height: 20px;
        font-weight: 700;
        word-break: break-word;
      }

      &-info {
        width: 50%;
        margin-left: 8px;
        font-size: 12px;
        line-height: 20px;
        color: rgba(13, 27, 51, 1);
        word-break: break-word;
      }
    }

    &-item:last-child {
      border-bottom: none;
    }
  }
}

.pop-up-footer {
  padding: 36px 36px;
  border-top: 1px solid rgba(116, 116, 116, 1);
}
</style>

<style lang='scss'>
@import "../../assets/scss/mixins";
.multiselect__content-wrapper {
  width: 95%;
  margin-left: 20px; 
}
.multiselect__option {
  background: white;
  color: rgba(var(--vs-primary), 1);
  border-bottom: 1px solid #e8e8e8;
}

.multiselect__option--highlight {
  background: #f1f1f1;
}

.multiselect__option--highlight:after {
  display: none;
}

.multiselect__tags {
  border-radius: 50px !important;
  height: 49.2px;
  padding-top: 10px;
}

.multiselect__placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  width: 100%;
  padding-left: 6px !important;
  padding-top: 0 !important;
}

.multiselect__input {
  font-size: 13px !important;
  
}

.actionsPopupLimiteCorretor{
  display: flex;
  justify-content: flex-end;
}

.saveButtonLimiteCorretor{
  width: 120px;
  margin: 2px;
  margin-bottom: 5px;
  border: 1px solid #0e65a5;
}

.cancelButtonLimiteCorretor{
  margin: 2px;
  color: black;
  border: 1px solid rgba(142, 141, 142, 1);
  margin-bottom: 5px;
}
.button-drop-down{
  background: #0e65a5;
  color: white;
  padding:5px;
  min-width: 113px;
  max-width: 113px;
}
.vs-table--tbody{
z-index: 49;
}
.iconAdjust{
  position: relative;
  top: 2px;
  left: 10px;
}
</style>
